import twitter from "../../img/linkLogos/twitter.svg";
import gitHub from "../../img/linkLogos/github.svg";
import linkedIn from "../../img/linkLogos/linkedin.svg";

import './footer.css';
import email from "../../img/linkLogos/email.svg";
import itch from "../../img/linkLogos/itch-io.svg";

const Footer = () => {
    return ( 
        <footer className="footer">
			<div className="footer__container">
				<div className="footer_socials">
					<a href="https://github.com/psikh286" target="_blank" rel="noreferrer">
						<img className="footer__icon" src={gitHub} alt="Link" />
					</a>
					<a href="mailto:alan@berberov.dev" target="_blank" rel="noreferrer">
						<img className="footer__icon" src={email} alt="Link" />
					</a>
					<a href="https://www.linkedin.com/in/alan-berberov-53844027a/" target="_blank" rel="noreferrer">
						<img className="footer__icon" src={linkedIn} alt="Link" />
					</a>
					<a href="https://psikh286.itch.io/" target="_blank" rel="noreferrer">
						<img className="footer__icon" src={itch} alt="Link" />
					</a>
					<a href="https://twitter.com/alan_berberov" target="_blank" rel="noreferrer">
						<img className="footer__icon" src={twitter} alt="Link" />
					</a>
				</div>
				<p className="footer__copyright">©Alan Berberov</p>
			</div>
		</footer>
     );
}
 
export default Footer;