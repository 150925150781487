import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from 'react-router-dom';
// import { createHashRouter, RouterProvider} from 'react-router-dom';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    
  <App />
 
);


reportWebVitals();
